import React, { useEffect, useRef, useState } from 'react';
import ArrowRight from '@material-ui/icons/ArrowRight'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Button from '@salesforce/design-system-react/components/button';
import { adjustTextareaHeights } from "services/adjustTextareaHeight";
import { CQAppConstant } from "app-constants";


const CQSectionHeader = (props) => {
  const [title, setTitle] = useState('Section Header Title Area');
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea:any = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }, [title]);

  useEffect(() => {
    if(props.isFormBuilder && props.title.hasOwnProperty('elements')){
        if(props.title.elements[0].hasOwnProperty('label')){
          setTitle(props.title.elements[0].label);
            if(!props.title.elements[0].label.includes(title)){
              setTitle(props.title.elements[0].label);
            }
        }
    }
  },[])

 const deleteSection = (evt : any) => {
    props.setIsdropAreaUpdated(false);
    setTimeout(() => {
      props.setIsdropAreaUpdated(true);
      updateUISchema({type: 'delete', value: {
        index : evt.target.id.split('_')[2]
      }
    });
    }, 2000)
 }

const changeTitle = (evt: any) => {
  setTitle(evt.target.value);
  adjustTextareaHeights(evt);
  setTimeout(() => {
    updateUISchema({type : 'section', value : {
          data : evt.target.value,
          index : evt.target.id.split('_')[2]
        }
    })
  }, 2000)
}

  const updateUISchema = (obj : any) => {
    const updateUISchema = new CustomEvent(
      CQAppConstant.EVENT_LISTENER.CQ_UPDATE_SECTION,
      {
        bubbles: true,
        detail: {
          type: obj.type,
          value: obj.value
        },
      }
    );

    document.dispatchEvent(updateUISchema);
  };

  return (
    <>
      <div className={props.isFormBuilder ? "slds-accordion__summary cq-section-header slds-grid slds-wrap" : "slds-accordion__summary cq-section-header"}>
        <h2 className={props.isFormBuilder ? "slds-accordion__summary-heading slds-size_11-of-12" : "slds-accordion__summary-heading"}onClick={ () => props.toggle(props.index)}>
          {
            props.isToggle?
              <ArrowRight/> :
              <ArrowDropDown/> 
          }
          {
            
            props.isFormBuilder?
                  <textarea
                    ref={textareaRef}
                    id ={ 'cq_section_' + props.index}
                    style={{width:'99%', border: '0.5px solid white'}} 
                    onChange={changeTitle}
                    value={title}
                  />
            :
              <span className="cq-section-content slds-accordion__summary-content">{props.title}</span> 
          }
        </h2>
        {
          props.isFormBuilder ? 
            <div className='slds-size_1-of-12 slds-p-left_xx-large' style={{display: 'flex', justifyContent : 'space-around'}}>
                <Button
                  id={'delete_id_'+ props.index}
                  iconCategory="utility"
                  iconName="delete"
                  iconSize="large"
                  variant="icon"
                  onClick={deleteSection}
                />
            </div>
            : null
        }
      </div>
    </>
  )


}


export default CQSectionHeader;